// import { tabUnstyledClasses } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";

function RangeBurger() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <MainDiv>
        <Paragraph>
          <Spann>אימון לחידוש רישיון פרטי:</Spann>
          <div>ימים א׳-ה׳ בשעות 10:00, 14:00, 17:00.</div>
          <div>ימי ו' וערבי חג: 9:00, 10:00, 11:00, 12:00.</div>
          <div>יש להגיע כ-20 דק׳ לפני לצורך קליטה ורישום.</div> 
          <div>נא להקפיד להגיע עם הצהרת בריאות חתומה ע״י רופא משפחה.</div>
        </Paragraph>

        <Paragraph>
          <Spann> אימון לרענון תקופתי: </Spann>
          <div>ימים א׳-ה׳ בשעות 10:00, 14:00, 17:00.</div>
          <div>ימי ו' וערבי חג: 9:00, 10:00, 11:00, 12:00.</div>
          <div>יש להגיע כ-20 דק׳ לפני לצורך קליטה ורישום.</div> 
        </Paragraph>

        <Paragraph>
          <Spann>אימון לקבלת רישיון חדש (4.5 שעות): </Spann>
          <div>יש לתאם הכשרה ראשונית במספר הטלפון: <br/> <a href="tel:09-7665533"> 09-7665533</a>.</div>
          <div>יש להגיע כ-20 דק׳ לפני לצורך קליטה ורישום.</div> 
        </Paragraph>

        <Paragraph>
          <Spann> אימון שמירת כשירות (4.5 שעות) לאנשי אבטחה:</Spann>
          <div>יש לתאם במספר הטלפון: <a href="tel:09-7665533"> 09-7665533</a>.</div>
          <div>יש להגיע כ-20 דק׳ לפני לצורך קליטה ורישום.</div> 
        </Paragraph>

        <Paragraph>
          <Spann>אימון לקבלת רישיון ארגוני (9 שעות): </Spann>
          <br />
          <span>
            לאחר הגשה ואישור משרד לבטחון פנים.<br/> בתיאום מראש.
          </span>
        </Paragraph>
      
        <Paragraph>
          <Spann> ירי חופשי לבעלי רישיון כלי ירייה:</Spann>
          <br />
          <div>ימים א׳-ה׳: 8:30.</div>
        </Paragraph>

        <Paragraph>
          <Spann> בדיקת תקינות כלי ירייה: </Spann>
          <br />
          ניתן להגיע בכל עת בשעות הפעילות.
        </Paragraph>

      </MainDiv>
      <Paragraph>*האימונים לכלל סוגי כלי הירייה.</Paragraph>
    </Container>
  );
}

export default RangeBurger;

const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 24px;
  font-size: 20px;
  font-weight: 600;
  padding: 0 24px;
  margin: 4rem auto;

  @media (max-width: 768px) {
    font-size: 16px;
    margin: 0 auto 2rem auto; 
  }
`

const MainDiv = styled.div`
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 20px;
  
`
const Spann = styled.span`
  color: #005eb8;
`
const Paragraph = styled.div`
  width: 400px;
  text-align: right;
  padding: 20px 0;
  line-height: 1.3;

  @media (max-width: 768px) {
    width: 100%;
  }
`

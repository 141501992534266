import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';



const initialState = {
  Value: false,

};

export const incrementAsync = createAsyncThunk(
  'user/userConnect',
  async () => {

  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.Value =  action.payload
    },
    deleteUser: (state, action) => {
      state.Value = false;
    },
   
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {

      })
      .addCase(incrementAsync.fulfilled, (state, action) => {

        state.basketValue = action.payload;
      });
  },
});

export const { addUser, deleteUser } = userSlice.actions;



export default userSlice.reducer;

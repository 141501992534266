import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';



const initialState = {
  filterValue: "",

};

export const incrementAsync = createAsyncThunk(
  'basket/basketShop',
  async () => {

  }
);

export const filterSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    addItemToFilter: (state, action) => {
      state.filterValue =  action.payload
    },
    deleteFIlter: (state, action) => {
      state.filterValue = "";
    },
   
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {

      })
      .addCase(incrementAsync.fulfilled, (state, action) => {

        state.basketValue = action.payload;
      });
  },
});

export const { addItemToFilter, deleteFIlter } = filterSlice.actions;



export default filterSlice.reducer;

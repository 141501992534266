import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Input, message } from "antd";
import { writeUserData } from "../FBase/RealTimeFirebase";
// mui radio import
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { uploadFileFirestore } from "../FBase/base";
import AntDProgress from "./AntDProgress";

const { TextArea } = Input;

function FormNotification() {
  const [title, setTitle] = useState("");
  const [mainMessage, setMainMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [message3, setMessage3] = useState("");
  const [radoVal, setRadoVal] = useState(null);
  const [percent, setPercent] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  // const [dyLink, setdyLink] = useState("");
  const [webLink1, setWebLink1] = useState("");
  // const [webLink2, setWebLink2] = useState("");
  const [phoneLink1, setPhoneLink1] = useState("");
  const [phoneLink2, setPhoneLink2] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    const file = e.target[1]?.files[0];
    try {
      if(file){
        uploadFileFirestore(file.name, file, file.type, setPercent, setImageUrl);
      }
      writeUserData(
        title,
        mainMessage,
        radoVal,
        imageUrl,
        webLink1,
        phoneLink1,
        phoneLink2,
        message2,
        message3
      );
    } catch (err) {
      message.error(err);
      console.log(err);
    }
  }
  // input on change
  // const fileChanfeHandle = (data) => {
  //   // console.log(data.target.value);
  //   let dataVal = data.target.value;
  //   let fileName = dataVal.split("\\")[2] || '';
  //   console.log(fileName);
  //   // uploadFileFirestore(fileName);
  // };

  useEffect(() => {
    if (imageUrl) {
      try {
        writeUserData(title, mainMessage, radoVal, imageUrl);
      } catch (err) {
        message.error(err.message)
      } 
    }
  }, [imageUrl, title, mainMessage, radoVal]);

  return (
    <Container>
      <UiForm>
        <h1>טופס שליחת עדכונים</h1>
        <form onSubmit={handleSubmit}>
          <InputContainer>
            <LabelNew htmlFor="title">כותרת</LabelNew>
            <Input
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              // value={title}
              size="large"
              name="title"
              id="title"
              placeholder="כתיבת כותרת"
              required
              value={title}
              // value={radoVal === 'range' ? "עדכוני מטווח" : radoVal === 'store' ? "עדכוני חנות" : radoVal === 'club' ? "עדכוני מועדון" : '' }
            />
          </InputContainer>
          <InputContainer>
            <LabelNew htmlFor="mess">בחירת תמונה</LabelNew>
            <input
              accept=".jpg, .jpeg, .png,.webp"
              type="file"
              id="myFile"
              name="filename"
              // onChange={fileChanfeHandle}
            ></input>
            <AntDProgress percent={percent} setPercent={setPercent} />
            {imageUrl && (
              <div
                style={{
                  width: "350px",
                  height: "50px",
                  color: "green",
                  fontWeight: "700",
                }}
              >
                העלאה התבצעה בהצלחה
              </div>
            )}
            {/* <UploadButton onClick={handleUpload}>שליחת קובץ</UploadButton> */}
          </InputContainer>
          <InputContainer>
            <LabelNew htmlFor="mess">הודעה</LabelNew>
            <TextArea
              rows={4}
              id="mess"
              allowClear
              placeholder="כתיבת הודעה"
              value={mainMessage}
              onChange={(e) => {
                setMainMessage(e.target.value);
              }}
              required
            />
          </InputContainer>
          <InputContainer>
            <TextArea
              rows={2}
              id="mess2"
              allowClear
              placeholder="פיסקה שניה"
              value={message2}
              onChange={(e) => {
                setMessage2(e.target.value);
              }}
              
            />
          </InputContainer>
          <InputContainer>
            <TextArea
              rows={2}
              id="mess3"
              allowClear
              placeholder="פיסקה שלישית"
              value={message3}
              onChange={(e) => {
                setMessage3(e.target.value);
              }}
              
            />
          </InputContainer>
          {/* <DynamicLync href={dyLink}>{dyLink}</DynamicLync> */}
          {/* Links input sections */}
          <InputContainer>
            <LabelNew htmlFor="title">הוספת קישורים להודעה</LabelNew>
            <Input
              onChange={(e) => {
                setWebLink1(e.target.value);
              }}
              // value={title}
              size="large"
              name="webLink1"
              id="webLink1"
              placeholder="כתובת אתר"
              
              value={webLink1}
            />
          </InputContainer>
          {/* <InputContainer>
            <Input
              onChange={(e) => {
                setWebLink2(e.target.value);
              }}
              // value={title}
              size="large"
              name="webLink2"
              id="webLink2"
              placeholder="כתובת אתר"
              
              value={webLink2}
            />
          </InputContainer> */}
          <InputContainer>
            <Input
              onChange={(e) => {
                setPhoneLink1(e.target.value);
              }}
              // value={title}
              size="large"
              name="phoneLink1"
              id="phoneLink1"
              placeholder="WhatsApp מספר טלפון"
              
              value={phoneLink1}
            />
          </InputContainer>
          <InputContainer>
            <Input
              onChange={(e) => {
                setPhoneLink2(e.target.value);
              }}
              // value={title}
              size="large"
              name="phoneLink2"
              id="phoneLink2"
              placeholder="מספר טלפון"
              
              value={phoneLink2}
            />
          </InputContainer>

          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              בחירת סוג עדכון
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              required
            >
              <FormControlLabel
                value="range"
                control={<Radio />}
                label="עדכוני מטווח"
                onClick={(e) => {
                  setRadoVal(e.target.value);
                }}
              />
              <FormControlLabel
                value="store"
                control={<Radio />}
                label="עדכוני חנות"
                onClick={(e) => {
                  setRadoVal(e.target.value);
                }}
              />

              <FormControlLabel
                value="club"
                control={<Radio />}
                label="עדכוני מועדון"
                onClick={(e) => {
                  setRadoVal(e.target.value);
                }}
              />
            </RadioGroup>
          </FormControl>
          <ButtonNew type="submit">שלח</ButtonNew>
        </form>
      </UiForm>
    </Container>
  );
}

export default FormNotification;

const Container = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;
const UiForm = styled.div`
  width: 400px;
  /* border: 1px solid #0053c4; */
  padding: 1rem;

  @media (max-hight: 350px) {
    width: 100%;
  }
`;
// const DynamicLync = styled.a``;
const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  margin: 0.5rem auto;
  gap: 3px;
`;
const LabelNew = styled.label`
  width: fit-content;
  font-size: 16px;
  font-weight: 600;
`;
const ButtonNew = styled.button`
  width: 50%;
  margin: auto;
  padding: 5px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid #3b5998;
  color: #fff;
  font-weight: 600;
  background-color: #005eb8;
  :active{
    background-color: hsl(209.3,100%,46.1%)
  }
`;

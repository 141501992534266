import React from "react";
import styled from 'styled-components';

function Accessibility() {
  return (
    <Conrainer>
        <h2>הצהרת נגישות</h2>
      <p>
        אתר זה עומד בדרישות תקנות שיוויון זכויות לאנשים עם מוגבלות (התאמות
        נגישות לשירות), התשע"ג 2013.
      </p>
      <p>
        התאמות הנגישות בוצעו עפ"י מסמך WCAG2.0 הבינלאומי והמלצות התקן הישראלי
        (ת"י 5568) לנגישות תכנים באינטרנט ברמת בסיס. (AA)
      </p>
      <p>
        ההתאמות נבחנו והותאמו לתצוגה בדפדפנים הנפוצים ולשימוש בטלפון הסלולרי.
      </p>
      <p>
        האתר מספק מבנה סמנטי עבור טכנולוגיות מסייעות ותמיכה בדפוס השימוש המקובל
        להפעלה עם מקלדת בעזרת מקשי החיצים, Enter ו- Esc ליציאה מתפריטים וחלונות.
      </p>
      <ul>
        <li>אמצעי הניווט באתר פשוטים וברורים.</li>
        <li>
          תכני האתר כתובים באופן ברור, מסודר והיררכי. במידת הצורך קיימים הסברים
          לצד התוכן.
        </li>
        <li>האתר מותאם לצפייה בדפדפנים מודרנים.</li>
        <li>התאמת האתר לתצוגה תואמת מגוון מסכים ורזולוציות.</li>
        <li>תוכן האתר כתוב בשפה פשוטה וברורה.</li>
        <li>כל הדפים באתר בעלי מבנה קבוע.</li>
        <li>לכל התמונות באתר יש הסבר טקסטואלי חלופי (alt)</li>
        <li>
          האתר מאפשר שינוי גודל הגופן על ידי שימוש במקש Ctrl וגלגלת העכבר.
        </li>
        <li>אין באתר שימוש בתצוגת טקסט נע או מהבהב.</li>
      </ul>
      <p>
        חשוב לציין, כי למרות מאמצינו הגדולים להנגיש את כלל הדפים באתר, ייתכן
        ויתגלו חלקים שלא הונגשו כראוי או שטרם הונגשו. אם נתקלתם בבעיה בנושא
        הנגישות באתר זה, נשמח לקבל הערות ובקשות באמצעות פנייה ברורה לדף צור קשר
        שבאתר. על מנת שנוכל לטפל בבעיה בדרך היעילה והמהירה ביותר, אנו ממליצים
        לצרף פרטים מלאים ככל שניתן. תודה!
      </p>
    </Conrainer>
  );
}

export default Accessibility;

const Conrainer = styled.div`
height: auto ;
min-height: 60vh ;
font-size:22px ;
width:100% ;
text-align:right ;
padding:5px ;
h2{
    margin-top:3rem ;
}
p{
    margin-top:1rem ;
    margin-right: 10px ;
}
@media(max-width:360px){
    font-size:16px ;
}
`

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { paginateGetDataFromStoreQuery, NextPaginateGetDataFromStoreQuery } from '../../FBase/base'


const initialState = {
  shopValue: [],

};

export const incrementAsync0 = createAsyncThunk(
  'shop/fetchShop',
  async () => {
    let fetchBags = await paginateGetDataFromStoreQuery("ipsc")
    return fetchBags
  }
);
export const incrementAsync0Next = createAsyncThunk(
  'shop/fetchShop',
  async () => {
    let fetchBags = await NextPaginateGetDataFromStoreQuery("ipsc")
    console.log(fetchBags);
    return fetchBags
  }
);
export const incrementAsync1 = createAsyncThunk(
  'shop/fetchShop',
  async () => {
    let fetchBags = await paginateGetDataFromStoreQuery("handguns")
    return fetchBags
  }
);
// export const incrementAsync1 = createAsyncThunk(
//   'shop/fetchShop',
//   async () => {
//     let fetchBags = await paginateGetDataFromStoreQuery("handguns")
//     return fetchBags
//   }
// );
export const incrementAsync2 = createAsyncThunk(
  'shop/fetchShop',
  async () => {
    let fetchBags = await paginateGetDataFromStoreQuery("pcc")
    return fetchBags
  }
);
export const incrementAsync3 = createAsyncThunk(
  'shop/fetchShop',
  async () => {
    let fetchBags = await paginateGetDataFromStoreQuery("roni")
    return fetchBags
  }
);
export const incrementAsync4 = createAsyncThunk(
  'shop/fetchShop',
  async () => {
    let fetchBags = await paginateGetDataFromStoreQuery("ipscHolsters")
    return fetchBags
  }
);
export const incrementAsync5 = createAsyncThunk(
  'shop/fetchShop',
  async () => {
    let fetchBags = await paginateGetDataFromStoreQuery("belts")
    return fetchBags
  }
);
export const incrementAsync6 = createAsyncThunk(
  'shop/fetchShop',
  async () => {
    let fetchBags = await paginateGetDataFromStoreQuery("magholster")
    return fetchBags
  }
);
export const incrementAsync7 = createAsyncThunk(
  'shop/fetchShop',
  async () => {
    let fetchBags = await paginateGetDataFromStoreQuery("bags")
    return fetchBags
  }
);
export const incrementAsync8 = createAsyncThunk(
  'shop/fetchShop',
  async () => {
    let fetchBags = await paginateGetDataFromStoreQuery("cleaning")
    return fetchBags
  }
);
export const incrementAsync9 = createAsyncThunk(
  'shop/fetchShop',
  async () => {
    let fetchBags = await paginateGetDataFromStoreQuery("safe")
    return fetchBags
  }
);
export const incrementAsync10 = createAsyncThunk(
  'shop/fetchShop',
  async () => {
    let fetchBags = await paginateGetDataFromStoreQuery("general")
    return fetchBags
  }
);

export const shopSlice = createSlice({
  name: 'shop',
  initialState,

  reducers: {
    increment: (state) => {

      state.shopValue += 1;
    },
    decrement: (state) => {
      state.shopValue -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action) => {
      state.shopValue = [...action.payload];
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync0.pending, (state) => {

      })
      .addCase(incrementAsync0.fulfilled, (state, action) => {
        //  console.log(action.payload);
        state.shopValue = action.payload;
      });
  },
 
});

export const { increment, decrement, incrementByAmount } = shopSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state) => state.counter.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

export default shopSlice.reducer;

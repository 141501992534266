import React, { Suspense } from 'react'
import {
    Switch,
    Route,
} from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import FormNotification from './components/FormNotification';
import RangeBurger from './components/RangeBurger';

import PrivateRoute from './components/PrivateRoute'
import Accessibility from './components/Accessibility';
// main pages
// import Home from './pages/Home';
// import AlonClub from './pages/AlonClub';
// import Shop from './pages/Shop';
// import Courses from './pages/Courses';

// courses sub route
// import SuperviserCourse from './components/superviserCourse';
// import ShootingExperience from './components/shootingExperience';
// import InstracturCourse from './components/InstracturCourse';
// import IpscCourse from './components/IpscCourse';
// utills
// import Basket from './components/Basket';
// import Page404 from './components/Page404';
// import AboutFooter from './components/AboutFooter';
// import DownloadDocs from './components/DownloadDocs';
// react lazy 
const Home = React.lazy(() => import('./pages/Home'));
const AlonClub = React.lazy(() => import('./pages/AlonClub'));
const Shop = React.lazy(() => import('./pages/Shop'));
const Courses = React.lazy(() => import('./pages/Courses'));
// sub lazy route
const SuperviserCourse = React.lazy(() => import('./components/superviserCourse'));
const ShootingExperience = React.lazy(() => import('./components/shootingExperience'));
const InstracturCourse = React.lazy(() => import('./components/InstracturCourse'));
const IpscCourse = React.lazy(() => import('./components/IpscCourse'));
const Page404 = React.lazy(() => import('./components/Page404'));
const AboutFooter = React.lazy(() => import('./components/AboutFooter'));
const DownloadDocs = React.lazy(() => import('./components/DownloadDocs'));
const DashBoard = React.lazy(() => import('./components/DashBoard'));

// loader for suspense
const renderLoader = () => <div style={{height:"100vh",width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}><CircularProgress /></div>;


function AppRouter() {
    return (
        <Suspense fallback={renderLoader()}>
            <Switch>
            <PrivateRoute exact path="/notification" component={FormNotification}/>
            {/* <PrivateRoute exact path="/dashboard" component={DashBoard}/> */}

                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/shop">
                    <Shop />
                </Route>
                <Route path="/alonClub">
                    <AlonClub />
                </Route>
                {/* into private route */}
                <Route exact path="/dashboard">
                    <DashBoard/>
                </Route>
                {/* <Route exact path="/notification">
                    <FormNotification/>
                </Route> */}
                <Route exact path="/courses">
                    <Courses />
                </Route>
                <Route path="/courses/instructor">
                    <InstracturCourse />
                </Route>
                <Route path="/courses/supervisor">
                    <SuperviserCourse />
                </Route>
                <Route path="/accessibility">
                    <Accessibility/>
                </Route>
                <Route path="/courses/ipsc">
                    <IpscCourse />
                </Route>
                <Route path="/courses/shootingExperience">
                    <ShootingExperience />
                </Route>
                {/* <Route exact path="/basket">
                    <Basket />
                </Route> */}
                <Route exact path="/download">
                    <DownloadDocs />
                </Route>
                <Route exact path="/range">
                    <RangeBurger/>
                </Route>
                <Route exact path="/about">
                    <AboutFooter />
                </Route>
                <Route path='*'>
                    <Page404 />
                </Route>

            </Switch>
        </Suspense>


    )
}

export default AppRouter

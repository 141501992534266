import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';



const initialState = {
  Value: "",

};

export const incrementAsync = createAsyncThunk(
  'basket/basketShop',
  async () => {

  }
);

export const hotitemsSlice = createSlice({
  name: 'hotItems',
  initialState,
  reducers: {
    addItemTohotitems: (state, action) => {
      state.Value =  action.payload
    },
    deleteFIlter: (state, action) => {
      state.Value = "";
    },
   
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {

      })
      .addCase(incrementAsync.fulfilled, (state, action) => {

        state.basketValue = action.payload;
      });
  },
});

export const { addItemTohotitems, deleteFIlter } = hotitemsSlice.actions;



export default hotitemsSlice.reducer;

import React from 'react'
import styled from "styled-components";
import { motion } from "framer-motion";

function Ticker2() {
    return (
  
         <TickerWrap>
         <motion.div
         
           initial={{ x: -3800 }}
           animate={{ x: 800 }} 
           transition={{repeatType: "loop", repeat: Infinity,duration: 35 }}
           className="slider">
          <SliderItem>
          ברוכים הבאים לאתר האינטרנט החדש של מטווח כפר סבא!
            </SliderItem>
          <SliderItem>
          נשמח לעמוד לרשותכם בכל שאלה ופנייה
            </SliderItem>
         </motion.div>
       </TickerWrap>
    )
}

export default Ticker2

const TickerWrap = styled.div`
  
  position: relative;
    top: 10%;
    left: 0;
    width: 100%;
    height: 21px;
    overflow: hidden;
    margin: 10px 0;
    max-width: 1440px;
    margin: 0 auto;
    /* border: 1px solid black; */
    /* background-color: #005EB8; */
    color: #005EB8;
    font-weight: 700;
 
    .slider {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 3800px;
    display: flex;
    will-change: transform;
    /* background-color: black; */
  }
  
`;
const SliderItem = styled.div`
    position: relative;
    flex: 1;
    overflow: hidden;
    width: 100%;
   font-size:16px
    
    /* filter: grayscale(100%); */
`;
// const ImageDiv = styled.div`
//      display: inline-block;

// padding: 0 1rem;
// font-size: 1rem;
// color: white;   

// `;


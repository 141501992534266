import React, { useEffect } from 'react'
import styled from 'styled-components';

import {  useDispatch } from 'react-redux'
import { addUser } from '../features/user/userSlice'
import PopoverCom from './PopoverCom'

function BannerTop() {
    const dispatch = useDispatch();

    function sesseionStore() {
        if (sessionStorage.getItem('isLog') === null || sessionStorage.getItem('isLog') === 'false') {
            return false
        }
        return true
    }   

    useEffect(() => {
        dispatch(addUser(sesseionStore()))
    }, [dispatch])

    return (
        <BannerDiv>
            <Content>
                <Conect>
                    <PopoverCom/>
                </Conect>

                <LinksContainer>
                    <LinkBanner target='_blank' rel="noreferrer" href="https://www.gov.il/he/departments/policies/firearm-ammunition-regulations">השלמת תחמושת</LinkBanner>
                    <LinkBanner target='_blank' rel="noreferrer" href="https://www.gov.il/he/service/issue_firearms_license_to_a_private_individual\">הוצאת רישיון נשק</LinkBanner>
                    <LinkBanner target='_blank' rel="noreferrer" href="https://www.gov.il/he/service/private_firearm_license_renewal">חידוש רישיון נשק</LinkBanner>
                </LinksContainer >
            </Content>
        </BannerDiv >
    )
}

export default BannerTop

const BannerDiv = styled.div`
    width: 100%;
    background-color: #005EB8;
    display: flex;
    flex-direction: row;
    height: 45px;
    justify-content:space-between;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
`
const Content = styled.div`
    width: 100%;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:auto;
`

const LinkBanner = styled.a`
    border-radius: 20px;
    background-color: transparent;
    border: 1px #fff solid;
    color: #fff;
    font-weight: 700;
    padding: 3px 10px;
    transition: 250ms;
    margin-left: 2px;
    font-size: 12px ;
    align-items: center;
    justify-content: center;
    display: flex;

    &:hover{
        background-color: white;
        color: black;
        cursor: pointer;
    }

    @media (max-width: 560px) {
        font-size: 11px ;
    }

    @media (max-width: 350px) {
        padding: 3px 5px;
    }
`
const Conect = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const LinksContainer = styled.div`
    height: 100%;
    min-width: 85px;
    display: flex;
    margin: auto 10px;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media (max-width: 560px) {
        margin: 0 2px;
    }
`



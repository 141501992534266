import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';



const initialState = {
  basketValue: [],

};

export const incrementAsync = createAsyncThunk(
  'basket/basketShop',
  async () => {

  }
);

export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    addItemToBasket: (state, action) => {
      state.basketValue = [...state.basketValue, action.payload];
    },
    deleteBasket: (state, action) => {
      state.basketValue = [];
    },
   
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {

      })
      .addCase(incrementAsync.fulfilled, (state, action) => {

        state.basketValue = action.payload;
      });
  },
});

export const { addItemToBasket, deleteBasket } = basketSlice.actions;



export default basketSlice.reducer;

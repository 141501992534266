import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';



const initialState = {
    Value: [],

};

export const incrementAsync = createAsyncThunk(
    'blog/blogArrey',
    async () => {

    }
);

export const BlogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        addBlog: (state, action) => {
            state.Value = action.payload
        },
        deleteBlog: (state, action) => {
            state.Value = false;
        },

    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(incrementAsync.pending, (state) => {

            })
            .addCase(incrementAsync.fulfilled, (state, action) => {

                state.basketValue = action.payload;
            });
    },
});

export const { addBlog, deleteBlog } = BlogSlice.actions;



export default BlogSlice.reducer;

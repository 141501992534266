import {
    
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    updateProfile,
    sendPasswordResetEmail,
    GoogleAuthProvider,
    signInWithPopup,
    // onAuthStateChanged 
} from "firebase/auth";

import { auth } from '../FBase/base';
import {  message } from 'antd';




// onAuthStateChanged(auth, (user) => {
//     if (user) {
//       const uid = user.uid;
//       console.log(uid);
      
//     } else {
//       // User is signed out
//     //   signOutUser()

//     }
//   });

const provider = new GoogleAuthProvider();

export function googlePopUpLogin(){
signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    // const credential = GoogleAuthProvider.credentialFromResult(result);
    // const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    if(
        user.uid === 'FWAVojAyMLWxD2bW2T1yywy6Mp53' ||
        user.uid === '85yg0GP9eBYkYU2UjyhrqNSPX203' ||
        user.uid === 'FGr1jA2VnCRuzyRXRtXFw9Wcz9n1' ||
        user.uid === 'QtFBchUptXVLDu8EIlt7dNrMGnM2' ||
        user.uid === 'o3MgYssjeXftlG8Ou8clOeb2hfD2' 
   
        ){
         sessionStorage.setItem('isLog','true')
         message.success('התחברת בהצלחה')
         window.location.reload();
 
        }else{
         sessionStorage.setItem('isLog', 'false')
 
        }
    
   
  }).catch((error) => {
    // Handle Errors here.
    // const errorCode = error.code;
    const errorMessage = error.message;
    message.error(errorMessage)
    // The email of the user's account used.
    // const email = error.customData.email;
    // The AuthCredential type that was used.
    // const credential = GoogleAuthProvider.credentialFromError(error);
    
  });
}



export function SignUpPassword(email, password) {
    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            // const user = userCredential.user;
            // ...
        })
        .catch((error) => {
            const errorMessage = error.message;
            console.log(errorMessage);
        });
}
// Sign in existing users
export function signInPassword(email, password, call) {
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // console.log(userCredential.user);
            // console.log(email);
            // console.log(password);
            call()
            message.success('התחברת בהצלחה');


        })
        .catch((error) => {
            console.log(error.code);
            switch (error.code) {
                case "auth/invalid-email":
                    message.error('כתובת מייל לא תקינה');
                    break;
                case "auth/wrong-password":
                    message.error('סיסמה שגויה');
                    break;
                case "auth/user-not-found":
                    message.error('משתמש לא קיים');
                    break;
                default:
                    break;
            }
        });
}

export function getUserData() {
    const user = auth.currentUser;
    if (user !== null) {
        // The user object has basic properties such as display name, email, etc.
        const displayName = user.displayName;
        const email = user.email;
        // const photoURL = user.photoURL;
        // const emailVerified = user.emailVerified;
        return { displayName: displayName, email: email }
    }
}

export function signOutUser() {
    signOut(auth).then(() => {
        // Sign-out successful.
        sessionStorage.setItem("isLog",false)
        message.success('התנתקות בהצלחה');
    }).catch((error) => {
        // An error happened.
        console.log(error.message);
    });
}


export function resetEmail(email) {
    sendPasswordResetEmail(auth, email)
        .then(() => {
            console.log('Password reset email sent!');
        })
        .catch((error) => {
            // const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage);
        });
}
export function updateUser(name) {
    updateProfile(auth.currentUser, {
        displayName: name 
    }).then(() => {
        console.log('Successfully updated user');
    }).catch((error) => {
        console.log(error);
    });
}

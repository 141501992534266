import React from "react";
// material ui poOver
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signOutUser } from "../FBase/AuthFirebase";
import { deleteUser } from "../features/user/userSlice";
import PersonIcon from '@mui/icons-material/Person';

import "../styles/PopoverStyle.css";

function PopoverCom() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector((state) => state.user.Value)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
 
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function signOut() {
    sessionStorage.setItem("isLog", false);
    dispatch(deleteUser());
    signOutUser();
    // window.location.reload()
  }
  
  return (
    <Badge
      color="th"
      variant="dot"
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      invisible={user ? false : true }
    >
      <div>
        <button
         className="buttonn"
          onClick={handleClick}
        >
          <PersonIcon sx={{color:'#000'}}/>
        </button>
        <Popover
          id={id}
          style={{ padding:'5px', overflow:"hidden",height:"auto" }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {user ? (
            <>
              <div className="connect">
                <Link
                  style={{ fontSize: "1rem",padding:'10px'}}
                  to="/notification"
                  className="connect"
                >
                  טופס שליחת עדכונים
                </Link>
              </div>
              <Button
                color="fourth"
                style={{ fontSize: "1rem",padding:'10px'}}
                onClick={signOut}
              >
                התנתקות
              </Button>
            </>
          ) : (
            <div style={{ fontSize: "1rem",padding:'5px'}} >
              <Link
                style={{ fontSize: "1rem",padding:'2px'}}
                to="/dashboard"
                className="connect"
              >
                התחברות
              </Link>
            </div>
          )}
        </Popover>
      </div>
    </Badge>
  );
}

export default PopoverCom;

import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import cartReducer from '../features/cart/cartSlice'
import shopReducer from '../features/shop/shopSlice'
import basketReducer from '../features/basket/basketSlice'
import homeshopReducer from '../features/homeShop/homeShopSlice'
import filterReducer from '../features/filter/filterSlice'
import hotItemsReducer from '../features/hotitems/hotitemsSlice'
import userReducer from '../features/user/userSlice'
import blogReducer from '../features/blog/blogSlicer'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    cart:cartReducer,
    shop:shopReducer,
    basket:basketReducer,
    homeShop:homeshopReducer,
    filter:filterReducer,
    hotItems:hotItemsReducer,
    user:userReducer,
    blog:blogReducer,

  },
});

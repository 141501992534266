import React from 'react';
import 'antd/dist/antd.css';
import { Progress } from 'antd';

function AntDProgress({percent, setPercent}) {
    
  return (
    <div>
        <Progress percent={percent} />
    </div>
  )
}

export default AntDProgress
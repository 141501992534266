
import { getDatabase, ref, set, onValue, push, remove } from "firebase/database";
import { app } from './base'


const database = getDatabase(app);

// Basic write operations
export function writeUserData(title, message,radoVal,imageUrl,webLink1,phoneLink1,phoneLink2,message2,message3) {
  const postListRef = ref(database, 'messages/'+ radoVal);
  const newPostRef = push(postListRef);
  set(newPostRef, {
    title: title,
    message: message,
    url:imageUrl,
    tel:phoneLink1,
    link:webLink1,
    phone:phoneLink2,
    message2:message2,
    message3:message3
  })
  .then(() => {
    console.log("succes");
  })
  .catch((error) => {
    console.log(error);
    throw new Error(error.message)

  });
}

// Read data -Listen for value events
export function readDataListen(fun,url) {
  let dataArrey = []
  const starCountRef = ref(database, `messages/`+url);
  onValue(starCountRef, (snapshot) => {
    const data = snapshot.val() ? snapshot.val() : [] ;
    dataArrey = Object.entries(data)
    // fun(addBlog(dataArrey)) 
    fun(dataArrey)
    
  });

}


export function deleteData(path,keyId) {
  const starCountRef = ref(database, `messages/`+path+"/"+keyId);
  remove(starCountRef)
  

}





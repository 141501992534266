import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs, query, where, limit } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, listAll, getMetadata, updateMetadata, uploadBytesResumable } from "firebase/storage";
import { getAuth  } from "firebase/auth";
import { getFunctions } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

// firebase private config
export const firebaseConfig = {
    apiKey: 'AIzaSyBDcme69SaJadSFOIpkxxiLE9NKE8XraU0',
    authDomain: 'mrhollanderllc-21f26.firebaseapp.com',
    databaseURL: 'https://mrhollanderllc-21f26-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'mrhollanderllc-21f26',
    storageBucket: 'mrhollanderllc-21f26.appspot.com',
    messagingSenderId: '184344241385',
    appId: '1:184344241385:web:f57cf447463133b6127c78',
    measurementId: 'G-B3EJX668SS'
};
// firebase init  
export const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
// firebase app check
initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider('6LfGfyUgAAAAALEsbXnCy4WP50M_hGbz_da3ZqDD'),
    isTokenAutoRefreshEnabled: true 
});
// get refer to storage and fireStore
const storage = getStorage();
const db = getFirestore();
export const auth = getAuth();
// storage firebase database
export const upFile = async (file) => {
    const storageRef = ref(storage, file.name);
    uploadBytes(storageRef, file).then((snapshot) => {
        console.log("uploaded file", file.name);
    });
}

export const getUriFile = (file) => {
    const fileRef = ref(storage, file)
    // let newarrey = []
    getDownloadURL(fileRef)
        .then((url) => {

            console.log(url);
            // newarrey.push(url)
            // console.log(newarrey);

        })
        .catch((error) => {
            switch (error.code) {
                case 'storage/object-not-found':
                    console.log("File doesn't exist");
                    break;
                case 'storage/unauthorized':
                    console.log("User doesn't have permission to access the object");
                    break;
                case 'storage/canceled':
                    console.log(" User canceled the upload");
                    break;

                case 'storage/unknown':
                    console.log("Unknown error occurred, inspect the server response");
                    break;
                default:

            }
        });
}

export const addToFirestore = async (currentCollection) => {
    try {

        const docRef = await addDoc(collection(db, currentCollection),);
        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export const getDataFromStore = async (correctollectionToFetch) => {
    try {
        let mainData = []
        const querySnapshot = await getDocs(collection(db, correctollectionToFetch))
        querySnapshot.forEach((doc) => {
            mainData.push(doc.data())
        });
        // console.log(mainData);

        return mainData
    } catch (error) {
        console.log(error.message);
    }
}

export const getAllDocsFromCollection = async (correctollectionToFetch) => {
    const querySnapshot = await getDocs(collection(db, correctollectionToFetch));
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
    });

}

export const getDataFromStoreQuery = async () => {
    let mainData = []
    const q = query(collection(db, "test"), where("name", "==", "glock19"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        mainData.push(doc.data())

    });
    console.log(mainData);
    return mainData

}

export const paginateGetDataFromStoreQuery = async (coll) => {
    let mainData = []

    const q = query(collection(db, coll));
    const querySnapshot = await getDocs(q);

    // const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    // const next = query(collection(db, coll),
    //     startAfter(lastVisible),
    //     limit(10));

    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        mainData.push(doc.data())

    });
    // console.log(mainData);
    return mainData

}
export const NextPaginateGetDataFromStoreQuery = async (coll) => {
    let mainData = []

    const q = query(collection(db, coll), limit(7));
    const querySnapshot = await getDocs(q);
    // const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    // console.log("lastVisible", lastVisible);

    // const next = query(collection(db, coll),
    //     startAfter(lastVisible),
    //     limit(10));
    // console.log(next);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        mainData.push(doc.data())

    });
    console.log(mainData);
    return mainData

}

export const getDataFromStoreQueryCz = async () => {
    let mainData = []

    const q = query(collection(db, "test"), where("name", "==", "cz01"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        mainData.push(doc.data())

    });
    console.log(mainData);
    return mainData

}

export const getListOfData = (folder) => {
    const listRef = ref(storage, folder);
    listAll(listRef)
        .then((res) => {
            res.prefixes.forEach((folderRef) => {
                console.log(folderRef);

                // All the prefixes under listRef.
                // You may call listAll() recursively on them.
            });
            res.items.forEach((itemRef) => {
                console.log(itemRef['_location']['path_']);

                // All the items under listRef.
            });
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
}

export const getMetaData = () => {
    const dataref = ref(storage, "ghost/holster/hydra-testo.jpg")
    getMetadata(dataref)
        .then((metadata) => {
            // Metadata now contains the metadata for 'images/forest.jpg'
            console.log(metadata.name);
        })
        .catch((error) => {
            // Uh-oh, an error occurred!
            console.log("cant get metadata");
        });


}

export const updateMetadataFunction = () => {
    const dataref = ref(storage, "ghost/holster/hydra-testo.jpg")
    const newMetadata = {
        customMetadata: {
            'location': 'Yosemite, CA, USA',
            'activity': 'Hiking',
            'title': "hydra-testo"
        }
    };

    updateMetadata(dataref, newMetadata)
        .then((metadata) => {
            // Updated metadata for 'images/forest.jpg' is returned in the Promise
            console.log(metadata.customMetadata.title);
        }).catch((error) => {
            // Uh-oh, an error occurred!
            console.log(error.message);
        });


}


export const getAllFilesFromFolderStore = (folder) => {
    const foldertRef = ref(storage, folder);

    listAll(foldertRef)
        .then((res) => {
            res.prefixes.forEach((folderRef) => {
                // All the prefixes under listRef.
                // You may call listAll() recursively on them.

            });
            res.items.forEach(async (itemRef) => {
                let filelocation = itemRef._location.path_
                // console.log(filelocation);
                getUriFile(filelocation)

                //  console.log(arrey);
                // console.log(itemRef.toString());

            });
        })
        .catch((error) => {
            // Uh-oh, an error occurred!
            console.log(error.message);
        });

}

export const queryFromShop = (searchWorld) => {
    const citiesRef = collection(db, "handguns");
    const q = query(citiesRef, where("brand", "==", searchWorld));
    console.log('query');
    getDocs(q)
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
            })
        })
        .catch((err) => {
            console.log(err);
        })


}

export const uploadFileFirestore = (fileName, file, type, setPercent, setImageUrl) => {
    const metadata = {
        contentType: type
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    const storageRef = ref(storage, 'uploads/' + fileName);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on('state_changed',
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            setPercent(Math.round(progress))
            // switch (snapshot.state) {
            //     case 'paused':
            //         console.log('Upload is paused');
            //         break;
            //     case 'running':
            //         console.log('Upload is running');
            //         break;
            //     default:
            //         console.log('default');

            // }
        },
        (error) => {
             throw new Error(error)
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            // switch (error.code) {
            //     case 'storage/unauthorized':
            //         // User doesn't have permission to access the object
            //         console.log('User doesnt have permission')
            //         throw new Error('User doesnt have permission')
            //     case 'storage/canceled':
            //         // User canceled the upload
            //         console.log('User canceled the upload')
            //         throw new Error('User canceled the upload')
            //     case 'storage/unknown':
            //         // Unknown error occurred, inspect error.serverResponse
            //         console.log('Unknown error occurred')
            //         throw new Error('Unknown error occurred')
            //     default:
            // }
        },
        () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setImageUrl(downloadURL)
            });
        }
    );



}
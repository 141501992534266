import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDataFromStore } from '../../FBase/base'

const initialState = {
    homeShopValue: 0,

};


export const homeShopFetchAsync = createAsyncThunk(
    'homeShop/fetchHomeShop',
    async () => {
        let homeshop = await getDataFromStore('homeshop')
        return homeshop

    }
)

export const homeShopSlice = createSlice({
    name: 'homeShop',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        increment: (state) => {

            state.homeShopValue += 1;
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        incrementByAmount: (state, action) => {
            state.homeShopValue += action.payload;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(homeShopFetchAsync.pending, (state) => {
               
            })
            .addCase(homeShopFetchAsync.fulfilled, (state, action) => {
                
                state.homeShopValue = action.payload;
            });
    },
});

export const { HomeShopFetchAsync } = homeShopSlice.actions;


export const selectCount = (state) => state.counter.value;


export const incrementIfOdd = (amount) => (dispatch, getState) => {
  
};

export default homeShopSlice.reducer;

import React, { Suspense, useState } from 'react';
import styled from 'styled-components';

// css from ant design and for component
import './App.css';
import 'antd/dist/antd.css'
// main components for the page
import BannerTop from './components/BannerTop';
import Ticker2 from './components/Ticker2';
import Header from './components/Header'
import AppRouter from './AppRouter';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// material ui, speeddial and icons
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import CircularProgress from '@mui/material/CircularProgress';

import { FaWaze } from 'react-icons/fa';
import { MdAccessible, MdCancel, MdMonitor, MdMonochromePhotos } from "react-icons/md";

// ant-disign and using notification for the 2 number options on whatsApp
import { notification } from 'antd';
import Drawer from '@mui/material/Drawer';
import {
  Link
} from "react-router-dom";
// import Checkbox from '@mui/material/Checkbox';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import { GrConnect } from "react-icons/gr";
import { BsPlusLg } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
// whatsApp number notification build
const openNotification = (placement) => {
  notification.open({
    message: 'חנות מטווח כפר סבא',
    description:
      <a className='AppbuttonTel' rel="noreferrer" target='_blank' href=" https://wa.me/9720559944888"><PhoneIcon fontSize="small" />&nbsp; 055-9944888</a>,
    placement,
    duration: 6,
    icon: <WhatsAppIcon style={{ color: '#108ee9' }} />,
  });
  notification.open({
    message: 'קורסי הדרכה וספורט',
    description:
      <a className='AppbuttonTel' rel="noopener noreferrer" target='_blank' href="https://wa.me/9720526208585"><PhoneIcon fontSize="small" />&nbsp; 052-6208585</a>,
    placement,
    duration: 6,
    style: {
      // width: 600,
      height: 100,
    },
    icon: <WhatsAppIcon style={{ color: '#108ee9' }} />,
  });
  notification.open({
    message: 'אימוני מתקדמים וירי חוויתי ברובה',
    description:
      <a className='AppbuttonTel' rel="noopener noreferrer" target='_blank' href="https://wa.me/9720549603676"><PhoneIcon fontSize="small" />&nbsp; 054-9603676</a>,
    placement,
    duration: 6,
    style: {
      // width: 600,
      height: 100,
    },
    icon: <WhatsAppIcon style={{ color: '#108ee9' }} />,
  });
};

// dynamic Footer import using react lazy 
const Footer = React.lazy(() => import('./components/Footer'));

function App() {
  const [open, setOpen] = useState(true)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // material ui
  const [toggleDrawer, settoggleDrawer] = useState(false)


  // material ui theme change
  const theme = createTheme({
    action: {
      hover: "#333"
    },
    palette: {
      type: 'dark',
      // background:"#fff",
      primary: {
        main: '#fff',
        contrastText: '#fff',
      },
      Secondary: {
        main: '#D8D8D8',
        background: "#333",
        contrastText: '#000',
      },
      th: {
        main: ' #33cc33',
        contrastText: '#000',
      },
      fourth: {
        main: '#000',
        contrastText: '#000',

      },
      text: {
        primary: '#000',
      },


    },
  })
  // for react Suspense loader
  const renderLoader = () => <CircularProgress />;

  // actions in vanilla js for accessability
  function changeFontSize(type) {

    let el = document.querySelectorAll('p');
    let elh1 = document.querySelectorAll('h1');
    let elh2 = document.querySelectorAll('h2');
    let elh3 = document.querySelectorAll('h3');
    let link_a = document.querySelectorAll('a');

    let nodejs_arrey = [el, elh1, elh2, elh3, link_a]

    nodejs_arrey.forEach((node) => {
      node.forEach((element) => {
        let fontSize = window.getComputedStyle(element).getPropertyValue('font-size');
        fontSize = parseFloat(fontSize);
        if (type === 'increase') {
          element.style.fontSize = (fontSize + 5) + 'px'
        } else {
          element.style.fontSize = (fontSize - 5) + 'px'
        }
      })

    })


  };
  function changeGeneralColor() {
    let divs = document.querySelectorAll('div')
    let paregraph = document.querySelectorAll('p')
    let header = document.querySelectorAll('header')
    // let muiId = document.querySelector('#SpeedDialbasicexample-actions')
    // console.log(muiId.id);
    divs.forEach((div) => {
      div.style.setProperty('background-color', '#333')
      if (
        div.className === 'MuiSpeedDial-actions MuiSpeedDial-actionsClosed css-1s28u9o-MuiSpeedDial-actions'
        || div.className === 'MuiSpeedDial-root MuiSpeedDial-directionUp css-13i7v-MuiSpeedDial-root'
        || div.id === 'SpeedDialbasicexample-actions'
      ) 
      {
        div.style.setProperty('background-color', 'transparent')

      }
      if(div.className === 'sc-llYSUQ esERya'){
      div.style.setProperty('background-color', '#333')
      div.style.zIndex = "1"

      }

    })
    paregraph.forEach((p) => {
      p.style.setProperty('color', 'hsl(235, 42%, 85%)')

    })
    header.forEach((p) => {
      p.style.setProperty('background-color', '#333')

    })

  }
  function changeGeneralColorMono() {
    let divs = document.querySelectorAll('div')
    let paregraph = document.querySelectorAll('p')
    let header = document.querySelectorAll('header')
    let mui = document.querySelector('.MuiSpeedDial-root MuiSpeedDial-directionUp css-1itlvcl-MuiSpeedDial-root')
    let h1 = document.querySelectorAll('h1')
    let h2 = document.querySelectorAll('h2')
    let link = document.querySelectorAll('a')
    // console.log(divs);
    
    divs.forEach((div) => {
      div.style.setProperty('background-color', 'gray')
      
      if (
        div.className === 'MuiSpeedDial-actions MuiSpeedDial-actionsClosed css-1s28u9o-MuiSpeedDial-actions'
        || div.className === 'MuiSpeedDial-root MuiSpeedDial-directionUp css-13i7v-MuiSpeedDial-root'
        || div.id === 'SpeedDialbasicexample-actions'
      ) 
      {
        div.style.setProperty('background-color', 'transparent')

      }
      if(div.className === 'sc-llYSUQ esERya'){
      div.style.setProperty('background-color', '#333')
      div.style.zIndex = "1"

      }

    })
    paregraph.forEach((p) => {
      p.style.setProperty('color', 'hsl(226, 49%, 49%)')

    })
    header.forEach((p) => {
      p.style.setProperty('background-color', 'gray')

    })
    h1.forEach((h) => {
      h.style.setProperty('color', 'hsl(226, 49%, 49%)')
      h.style.setProperty('background-color', 'transparent')

    })
    h2.forEach((h) => {
      h.style.setProperty('color', 'hsl(226, 49%, 49%)')

    })
    link.forEach((h) => {
      h.style.setProperty('color', 'hsl(226, 49%, 49%)')

    })
    mui.style.setProperty('background-color', '#fff')
    mui.style.setProperty('zIndex', '9999')
  }
  function changeLinksWithUnderline() {
    // let count = 3
    let link_a = document.querySelectorAll('a');
    link_a.forEach((link) => {
      link.style.setProperty('text-decoration', "underline")
    })
  }
  function changeAltElementsOfImgs() {
    let imgs = document.querySelectorAll('img')
    imgs.forEach((img) => {
      console.log(img.alt);
      const p = document.createElement("p");
      // const parent = p.parentElement
      img.addEventListener("mouseover", () => {
        img.parentElement.appendChild(p)
        p.textContent = img.alt
        p.style.display = 'block'
        p.style.zIndex = 3000
        p.style.color = "#fff"
        p.style.fontSize = '16px'
        p.style.fontWeight = 700
        p.style.position = "absolute"
        p.style.backgroundColor = "#333"
        p.style.top = '50%'
        p.style.left = '50%'
        p.style.height = 'fit-content'
        p.style.width = 'fit-content'
        img.style.position = 'relative'
        // parent.style.position = 'relative'



      })
      img.addEventListener("mouseleave", () => {
        p.style.display = 'none'
      })



    })
  }

  return (
    <AppContainer>
      {/* <FixedDiv id='customCursor'>
        <img src='../cursor/b32.png' alt='black cursor'></img>
      </FixedDiv> */}
      <div className='App'>

        <Drawer
          anchor='right'
          open={toggleDrawer}
          onClose={() => settoggleDrawer(false)}
          sx={{ width: "300px" }}

        >
          <DrawerContainer>

            <TitleHeader>
              <IconDiv onClick={() => { settoggleDrawer(false) }}>
                <MdCancel size={'20px'} />
              </IconDiv>
              <Title>
                תפריט נגישות
              </Title>
            </TitleHeader>

            <ContantDiv>
              <ButtonGroupContainer>
                <h2>גודל טקסט</h2>
                <ButtonGroup>
                  <CustomButton onClick={() => { changeFontSize('increase') }} className='first'><BsPlusLg size={'20px'} /></CustomButton>
                  {/* <CustomButton className='second'>גדול</CustomButton> */}
                  <CustomButton onClick={() => { changeFontSize('decrease') }} className='third'><BiMinus size={'23px'} /></CustomButton>
                </ButtonGroup>
              </ButtonGroupContainer>

              <ButtonGroupContainer>
                <h2>ניגודיות צבעים</h2>
                <ButtonGroup>
                  <CustomButton onClick={changeGeneralColor} className='first'><MdMonitor size={'23px'} />הפוכה</CustomButton>
                  {/* <CustomButton className='second'>הפוכה</CustomButton> */}
                  <CustomButton onClick={changeGeneralColorMono} className='third'><MdMonochromePhotos size={'23px'} />מונוכרום</CustomButton>
                </ButtonGroup>
              </ButtonGroupContainer>

              <ButtonGroupContainer>
                <h2>הדגשת מידע</h2>
                <DataSection>
                  <CheckBoxButton onClick={changeLinksWithUnderline}>
                    <GrConnect size={'15px'} />
                    <p>הדגשת קישורים</p>
                  </CheckBoxButton>
                  <CheckBoxButton onClick={changeAltElementsOfImgs}>
                    <GrConnect size={'15px'} />
                    <p>הדגש טקסט חלופי לתמונות</p>
                  </CheckBoxButton>
                  {/* <CheckBoxButton >
                    <GrConnect size={'15px'} />
                    <p>הדגשת סימון מוקד המקלדת</p>
                  </CheckBoxButton> */}
                  {/* <CheckBoxButton>
                    <GrConnect size={'15px'} />
                    <p>תיאור תמונות</p>
                  </CheckBoxButton> */}
                </DataSection>
              </ButtonGroupContainer>
              <Reset onClick={() => { window.location.reload() }}>איפוס</Reset>
              <Link style={{ fontSize: "18px" }} to="/accessibility" className="ant-dropdown-link"  >
                הצהרת נגישות
              </Link>
            </ContantDiv>


          </DrawerContainer>
        </Drawer>
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: 'fixed', bottom: 16, left: 20, zIndex: '9999' }}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          <SpeedDialAction
            key={'Accessibility'}
            icon={<MdAccessible />}
            tooltipTitle={'נגישות'}
            sx={{ backgroundColor: 'hsl(0, 0%, 58%)', color: "#fff", fontSize: "23px", zIndex: '9999' }}
            onClick={() => settoggleDrawer(true)}
          >
          </SpeedDialAction>
          <SpeedDialAction
            key={'Instegram'}
            icon={<InstagramIcon />}
            tooltipTitle={'Instegram'}
            onClick={() => { window.open('https://www.instagram.com/p/CTSKbzksi88/?utm_medium=copy_link', '_blank') }}
            sx={{ backgroundColor: 'rgb(131,58,180)', zIndex: '9999', backgroundImage: " radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);", color: "#fff" }}
          >
          </SpeedDialAction>
          <SpeedDialAction
            key={'FaceBook'}
            icon={<FacebookIcon sx={{ color: '#fff' }} />}
            tooltipTitle={'FaceBook'}
            onClick={() => { window.open('https://he-il.facebook.com/M.R.Hollander.LTD/', '_blank') }}
            sx={{ backgroundColor: '#3b5998', zIndex: '9999' }}
          >
          </SpeedDialAction>
          <SpeedDialAction
            key={'Waze'}
            icon={<FaWaze style={{ fontSize: "27px", color: "#000" }} />}
            tooltipTitle={'Waze'}
            onClick={() => { window.open('https://ul.waze.com/ul?place=ChIJP5kk0aY5HRURZgU8GmrfitQ&ll=32.18334040%2C34.92260010&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location', '_blank') }}
            sx={{ backgroundColor: '#7EC0EE', zIndex: '9999' }}
          >
          </SpeedDialAction>
          <SpeedDialAction
            key={'WhatsApp'}
            icon={<WhatsAppIcon sx={{ color: '#fff' }} />}
            tooltipTitle={'WhatsApp'}
            onClick={() => openNotification('bottomLeft')}
            sx={{ backgroundColor: '#25D366', zIndex: '9999' }}
          >
          </SpeedDialAction>

        </SpeedDial>

        <ThemeProvider theme={theme}>
          <BannerTop />
          <Ticker2 />
          <Header />
          <AppRouter />
          <Suspense fallback={renderLoader()}>
            <Footer />
          </Suspense>
        </ThemeProvider>
      </div>
    </AppContainer >
  );
}

export default App;

const AppContainer = styled.div`
  text-align: center;
  direction: rtl;
  background:#D8D8D8;
  font-family: Poppins,Arial,sans-serif;

  .MuiSpeedDial-root MuiSpeedDial-directionUp css-13i7v-MuiSpeedDial-root{
    z-index:9999 ;
    background-color:#fff
  }
  .MuiSpeedDial-actions MuiSpeedDial-actionsClosed css-1s28u9o-MuiSpeedDial-actions{
    z-index:9999 ;
    background-color:#fff
  }
  #SpeedDialbasicexample-actions{
    background-color:transparent;
  }

`
// const FixedDiv = styled.div`
// width: fit-content ;
// height:fit-content;
// position: absolute ;
// border-radius: 50% ;
// z-index:9999 ;

// `
const DrawerContainer = styled.div`
width:300px ;
height:100% ;
overflow:hidden ;
/* padding: 10px ; */
`
const TitleHeader = styled.div`
display:flex ;
width:100% ;
align-items:center ;
justify-content:space-between ;
/* gap:60px; */
background-color:hsl(209, 100%, 36%) ;

`
const ContantDiv = styled.div`
width:100% ;
height:100% ;
color:#fff ;
font-weight:700 ;
background-color: hsl(0, 0%, 80%) ;
display:flex ;
flex-direction:column;
align-items:center ;

`
const Title = styled.h2`
font-weight:700 ;
padding: 10px ;
margin:0 ;
`
const IconDiv = styled.div`
display:flex ;
justify-content:center ;
align-items:center ;
margin-left:4px ;
:hover{
  cursor:pointer ;
}
`
const ButtonGroupContainer = styled.div`
margin-top:1rem ;
display:flex ;
flex-direction:column ;
text-align:right ;
align-items: right ;
justify-content: right ;
h2{
  font-weight:900 ;
  margin-right:3px ;
}
`
const CustomButton = styled.button`
width:100%;
height:100% ;
background-color : hsl(209, 100%, 18%);
color:#fff;
padding: 1rem ;
border: 1px hsl(0, 0%, 80%) solid ;
font-weight:700 ;
display:flex ;
flex-direction:column ;
justify-content:center ;
align-items:center ;
:hover{
background-color :  hsl(209, 100%, 36%);
cursor: pointer;
}
:focus{
background-color :  hsl(209, 100%, 36%);
}
:active{
  background-color :  hsl(209, 100%, 46%);
}
`
const ButtonGroup = styled.div`
width:300px;
display:flex;
border-radius: 10px ;
.first{
border-bottom-left-radius: 10px ;
border-top-left-radius: 10px ;
}
.third{
border-top-right-radius: 10px ;
border-bottom-right-radius: 10px ;
}
`
const DataSection = styled.div`
width:290px;
display:flex;
flex-direction:column ;
align-items:right ;
background-color: hsl(0, 0%, 70%);
border-radius: 5px ;
`
// const CheckboxContainer = styled.div`
// padding:4px 10px ;
// display: flex ;
// justify-content: space-between ;
// align-items: center ;
// `
const CheckBoxButton = styled.button`
width:100% ;
display:flex ;
justify-content:space-between ;
border:none;
background-color:transparent ;
align-items:center ;
padding:10px ;
font-weight:700 ;
border-radius: 5px ;
color: hsl(209, 100%, 18%);


:hover{
  background-color: hsl(0, 0%, 60%) ;
  cursor: pointer;
}
:focus{
  background-color: hsl(0, 0%, 60%) ;
}
:active{
  background-color: hsl(0, 0%, 40%) ;
}
p{
  margin: 0 ;

}
`
const Reset = styled.button`
width:97% ;
border:none;
background-color: hsl(209, 100%, 18%) ;
margin: 1rem auto ;
border-radius:5px;
cursor: pointer;
:hover{
background-color :  hsl(209, 100%, 36%);
cursor: pointer;
}
:active{
  background-color :  hsl(209, 100%, 46%);
}
`

